body {
  font-family: "Inter", sans-serif !important;
  color: "#9E9E9E";
  background-color: rgb(13,13,13) !important;
}

.search {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px; /* Imposta la larghezza massima desiderata */
  margin: 20px auto; /* Centra il componente nella pagina e aumenta la distanza dal contenuto sopra */
  padding: 0 10px; /* Aggiunge un po' di padding per evitare che l'input tocchi i bordi */
  border-radius: 20px; /* Bordo arrotondato per un look più elegante */
  border: 1px solid #ccc; /* Colore del bordo */
  box-sizing: border-box; /* Assicura che il padding sia incluso nella larghezza totale */
  overflow: hidden; /* Impedisce l'overflow orizzontale */
}

.inputInput {
  color: white !important;
  height: 40px; /* Imposta l'altezza desiderata per il campo di ricerca */
  padding: 0 10px; /* Spaziatura interna */
  flex: 1; /* Consente al campo di input di espandersi per occupare tutto lo spazio disponibile */
  box-shadow: none; /* Rimuove eventuali ombre */
}

.inputInput input {
  height: 100%; /* Assicura che l'input riempia il contenitore */
  border: none; /* Rimuove il bordo dell'input */
  outline: none; /* Rimuove il bordo di focus predefinito */
  background: none; /* Rimuove lo sfondo predefinito */
  color: white; /* Imposta il colore del testo a bianco */
  width: 100%; /* Assicura che l'input riempia tutto lo spazio disponibile */
  box-sizing: border-box; /* Assicura che il padding sia incluso nella larghezza totale */
}

.iconButton {
  padding: 0; /* Rimuove la spaziatura predefinita dell'IconButton */
  background: none; /* Rimuove lo sfondo dell'IconButton */
  color: white !important;
}

/* Media query per migliorare l'aspetto su dispositivi mobili */
@media (max-width: 600px) {
  .search {
    padding: 0 5px; /* Riduce il padding sui dispositivi mobili */
    max-width: 90%; /* Imposta una larghezza massima relativa per dispositivi mobili */
    margin: 10px auto; /* Riduce la distanza dal contenuto sopra sui dispositivi mobili */
  }

  .inputInput {
    padding: 0 5px; /* Riduce la spaziatura interna sui dispositivi mobili */
  }
}





.MuiGrid-item {
  display: flex;
  justify-content: center;
}
/* Personalizzazione della scrollbar per Webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Larghezza della scrollbar */
}

::-webkit-scrollbar-track {
  background: #333333; /* Colore di sfondo della scrollbar */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #555555, #777777); /* Gradiente per la scrollbar */
  border-radius: 10px;
  border: 3px solid #333333; /* Bordi della scrollbar */
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #777777, #555555); /* Gradiente invertito al passaggio del mouse */
}

/* Personalizzazione della scrollbar per Firefox */
* {
  scrollbar-width: thin; /* Larghezza sottile della scrollbar */
  scrollbar-color: #555555 #333333; /* Colore del cursore e dello sfondo */
}
